export const LEFT_MENU = [
    {
        icon: 'mdi-view-dashboard',
        title: 'Dashboard',
        to: { name: 'dashboard' }
    },
    {
        icon: 'mdi-format-list-bulleted-square',
        title: 'Resume Templates',
        to: { name: 'resume-templates' }
    },
    {
        icon: 'mdi-format-list-bulleted-square',
        title: 'Cover Letter Templates',
        to: { name: 'cover-letter-templates' }
    },
    {
        icon: 'mdi-account-group',
        title: 'Users',
        to: { name: 'users' }
    },
    {
        icon: 'mdi-briefcase',
        title: 'Jobs',
        to: { name: 'jobs' }
    },
    {
        icon: 'mdi-file-account',
        title: 'Resumes',
        to: { name: 'resumes' }
    },
    {
        icon: 'mdi-file-account',
        title: 'Cover Letters',
        to: { name: 'cover-letters' }
    },
    {
        icon: 'mdi-format-list-bulleted',
        title: 'Categories',
        to: { name: 'categories' }
    },
    {
        icon: 'mdi-file-multiple-outline',
        title: 'Examples',
        to: { name: 'examples' }
    },
    {
        icon: 'mdi-api',
        title: 'Rytr Api',
        to: { name: 'rytr' }
    }
];
