import Vue from 'vue';
import store from '@/store';
import AuthModule from '@/store/auth';
import { isLoggedIn, guard } from '@/GuardUtil';
import { getModule } from 'vuex-module-decorators';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'entry',
        redirect: () => {
            if (getModule(AuthModule, store).isLoggedIn) {
                return 'dashboard';
            }

            return 'login';
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        beforeEnter: isLoggedIn
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue'),
        beforeEnter: guard
    },
    {
        path: '/resume-templates',
        name: 'resume-templates',
        component: () => import('@/views/ResumeTemplates.vue'),
        beforeEnter: guard
    },
    {
        path: '/cover-letter-templates',
        name: 'cover-letter-templates',
        component: () => import('@/views/CoverLetterTemplates.vue'),
        beforeEnter: guard
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('@/components/users/UserList.vue'),
        beforeEnter: guard
    },
    {
        path: '/jobs',
        name: 'jobs',
        component: () => import('@/components/jobs/JobList.vue'),
        beforeEnter: guard
    },
    {
        path: '/resumes',
        name: 'resumes',
        component: () => import('@/views/Resumes.vue'),
        beforeEnter: guard
    },
    {
        path: '/cover-letters',
        name: 'cover-letters',
        component: () => import('@/views/CoverLetters.vue'),
        beforeEnter: guard
    },
    {
        path: '/categories',
        name: 'categories',
        component: () => import('@/components/categories/CategoryList.vue'),
        beforeEnter: guard
    },
    {
        path: '/examples',
        name: 'examples',
        component: () => import('@/components/examples/ExampleList.vue'),
        beforeEnter: guard
    },
    {
        path: '/rytr-api',
        name: 'rytr',
        component: () => import('@/views/Rytr.vue'),
        beforeEnter: guard
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
