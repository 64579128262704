import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import NotificationInterface from '@/interfaces/NotificationInterface';

@Module({
    namespaced: true,
    name: 'notificationModule'
})
class NotificationModule extends VuexModule {
    private snackBar: NotificationInterface = {} as NotificationInterface;

    @Mutation
    public setSnackBarNotification(payload: NotificationInterface): void {
        this.snackBar = payload;
    }

    public get snackBarNotification(): NotificationInterface {
        return this.snackBar;
    }
}

export default NotificationModule;
