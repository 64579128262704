import { AxiosPromise } from 'axios';
import HttpAdapter from '@/adapters/HttpAdapter';

export default class AuthApi {
    private uri: string = process.env.VUE_APP_BACKEND_URI;

    public authenticate(credentials: { email: string; password: string }): AxiosPromise {
        return HttpAdapter.post(`${this.uri}/api/auth/login`, credentials);
    }

    public logout(): AxiosPromise {
        return HttpAdapter.post(`${this.uri}/api/auth/logout`);
    }

    public refreshToken(): AxiosPromise {
        return HttpAdapter.post(`${this.uri}/api/auth/refresh`);
    }
}
