import AuthApi from '@/services/api/AuthApi';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

@Module({
    namespaced: true,
    name: 'authModule'
})
class AuthModule extends VuexModule {
    private _isLoggedIn: boolean = false;
    private _token: string = localStorage.getItem('access_token') || '';

    public get isLoggedIn(): boolean {
        return Boolean(this._token.length);
    }

    public get token(): string {
        return this._token;
    }

    @Mutation
    public setLoggedIn(isLoggedIn: boolean): void {
        this._isLoggedIn = isLoggedIn;
    }

    @Mutation
    public setToken(token: string): void {
        localStorage.setItem('access_token', token);

        this._token = token;
    }

    @Mutation
    public clearToken(): void {
        this._token = '';
        localStorage.removeItem('access_token');
    }

    @Action
    public async logout(): Promise<undefined|Error> {
        try {
            await (new AuthApi()).logout();

            this.context.commit('clearToken');
            this.context.commit('setLoggedIn', false);
        } catch (e) {
            console.error(e);

            return e;
        }
    }

    @Action
    public async refreshToken(): Promise<string|Error> {
        try {
            const response = await (new AuthApi().refreshToken());
            const { access_token } = response.data;

            this.context.commit('setToken', access_token);

            return access_token;
        } catch (e) {
            this.context.commit('clearToken');
            this.context.commit('setLoggedIn', false);

            return e;
        }
    }
}

export default AuthModule;
