import store from '@/store';
import router from '@/router';
import HttpMethods from '@/constants/HttpMethods';
import axios, { AxiosRequestConfig } from 'axios';

const api = axios.create();
const successNotificationMethods = [HttpMethods.POST, HttpMethods.PUT, HttpMethods.DELETE];
const ignoreSnackBarUrls = ['login'];

const showSnackBar = (config: AxiosRequestConfig): boolean => ignoreSnackBarUrls.every(url => !config.url?.includes(url));

api.interceptors.request.use((config: AxiosRequestConfig) => {
    const token: string = store.getters['authModule/token'];

    config.headers.Accept = 'application/json';
    config.headers.Authorization = `Bearer ${token}`;

    return config;
}, error => {
    return Promise.reject(error);
});

api.interceptors.response.use(response => {
    const { config } = response;
    let msg = '';

    if (response.data.message) {
        msg = response.data.message;
    }

    if (response.data.success_message) {
        msg = response.data.success_message;
    }

    if (config.method && successNotificationMethods.includes(<HttpMethods>config.method) && showSnackBar(config)) {
        store.commit('notificationModule/setSnackBarNotification', {
            color: 'success',
            msg: msg || 'Success'
        });
    }

    return response;
}, error => {
    const originalRequest = error.config;
    let msg = '';

    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        store.dispatch('authModule/refreshToken').then((token: string) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;

            return api(originalRequest);
        }).catch(() => {
            router.push({ name: 'login' });
        });
    }

    // @TODO convert all error messages to similar response
    if (error.response.data.message) {
        msg = error.response.data.message;
    }

    if (error.response.data.error_message) {
        msg = error.response.data.error_message;
    }

    if (error.response.data.error) {
        msg = error.response.data.error;
    }

    if (error.response && showSnackBar(error.response.config)) {
        store.commit('notificationModule/setSnackBarNotification', {
            color: 'error',
            msg: msg || 'Error'
        });
    }

    return Promise.reject(error);
});

export default api;
