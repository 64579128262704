import Vue from 'vue';
import Vuex from 'vuex';
import AuthModule from '@/store/auth';
import ThemeModule from '@/store/theme';
import NotificationModule from '@/store/notification';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        authModule: AuthModule,
        themeModule: ThemeModule,
        notificationModule: NotificationModule
    }
});
