





























import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Watch } from 'vue-property-decorator';

const theme = namespace('themeModule');

@Component
export default class DotMenu extends Vue {
    @theme.Mutation
    public changeTheme!: () => void;

    @theme.Getter
    public darkTheme!: boolean;

    public menu: boolean = false;

    public changeThemeColor(): void {
        this.changeTheme();

        this.$vuetify.theme.dark = this.darkTheme;
    }
}
