


















import Vue from 'vue';
import { namespace } from 'vuex-class';
import { LEFT_MENU } from '@/models/MenuModel';
import Drawer from '@/components/core/Drawer.vue';
import AppBar from '@/components/core/AppBar.vue';
import { Component } from 'vue-property-decorator';
import MenuInterface from '@/interfaces/MenuInterface';
import AppFooter from '@/components/core/AppFooter.vue';
import SnackBarNotification from '@/components/common/SnackBarNotification.vue';

const auth = namespace('authModule');
const theme = namespace('themeModule');

@Component({
    components: {
        AppBar,
        Drawer,
        AppFooter,
        SnackBarNotification
    }
})
export default class App extends Vue {
    @auth.Getter
    public isLoggedIn!: boolean;

    @theme.Getter
    public menuMinimized!: boolean;

    public sideBarMenu: MenuInterface[] = LEFT_MENU.map(item => ({ ...item }));

    @theme.Getter
    public darkTheme!: boolean;

    public created(): void {
        this.$vuetify.theme.dark = this.darkTheme;
    }
}
