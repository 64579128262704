




































import Vue from 'vue';
import MenuInterface from '@/interfaces/MenuInterface';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Drawer extends Vue {
    @Prop({ required: true, type: Array })
    public menu!: MenuInterface[];

    @Prop({ required: true, type: Boolean })
    public minimized!: boolean;

    public drawer: boolean = true;
}
