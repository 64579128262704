











import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Watch } from 'vue-property-decorator';
import NotificationInterface from '@/interfaces/NotificationInterface';

const notification = namespace('notificationModule');

@Component
export default class SnackBarNotification extends Vue {
    public isActive: boolean = false;
    @notification.Getter
    public snackBarNotification!: NotificationInterface;

    @Watch('snackBarNotification')
    public notificationChanged(): void {
        this.isActive = true;
    }
}
