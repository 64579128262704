import store from '@/store';
import { Route } from 'vue-router';
import AuthModule from '@/store/auth';
import { getModule } from 'vuex-module-decorators';

let entryUrl: string|null = null;
const authModule = getModule(AuthModule, store);

// eslint-disable-next-line
export const guard = (to: Route, from: Route, next: (url?: string) => void) => {
    if (authModule.isLoggedIn) {
        if (entryUrl) {
            const url = entryUrl;
            entryUrl = null;

            return next(url);
        } else {
            return next();
        }
    }

    entryUrl = to.path;

    return next('/login');
};

// eslint-disable-next-line
export const isLoggedIn = (to: Route, from: Route, next: (url?: string) => void) => {
    if (authModule.isLoggedIn) {
        return next('/dashboard');
    }

    return next();
};
