




























import Vue from 'vue';
import { namespace } from 'vuex-class';
import DotMenu from '@/components/core/DotMenu.vue';
import { Component, Prop } from 'vue-property-decorator';

const auth = namespace('authModule');
const theme = namespace('themeModule');

@Component({
    components: {
        DotMenu
    }
})
export default class AppBar extends Vue {
    @Prop({ required: true, type: Boolean })
    public minimized!: boolean;

    @auth.Mutation
    public clearToken!: () => void;

    @auth.Mutation
    public setLoggedIn!: (isLoggedIn: boolean) => void;

    @theme.Mutation
    public changeMenuMinimize!: () => void;

    public logout(): void {
        this.clearToken();
        this.setLoggedIn(false);

        this.$router.push({ name: 'login' });
    }

    public get minimizeIcon(): string {
        return this.minimized ? 'mdi-chevron-right' : 'mdi-chevron-left';
    }
}
