import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import LocalStorageUtil from '@/utils/LocalStorageUtil';

@Module({
    namespaced: true,
    name: 'themeModule'
})
class ThemeModule extends VuexModule {
    public isDarkTheme: boolean = LocalStorageUtil.getThemeConfigurationValue('isDarkTheme') || true;
    public isMenuMinimized: boolean = LocalStorageUtil.getThemeConfigurationValue('isMenuMinimized');

    @Mutation
    public changeMenuMinimize(): void {
        this.isMenuMinimized = !this.isMenuMinimized;

        localStorage.setItem('isMenuMinimized', `${this.isMenuMinimized}`);
    }

    @Mutation
    public changeTheme(): void {
        this.isDarkTheme = !this.isDarkTheme;

        localStorage.setItem('isDarkTheme', `${this.isDarkTheme}`);
    }

    public get menuMinimized(): boolean {
        return this.isMenuMinimized;
    }

    public get darkTheme(): boolean {
        return this.isDarkTheme;
    }
}

export default ThemeModule;
